import { fetchEMUPage, fetchEMUDel } from '@/api/DDJ/v3/emu.js';
export default {
  components: {},
  data() {
    return {
      filter: {},
      options: {},
      list: [],
      loading: {
        list: false,
      },
      pages: {
        pageNum: 1,
        pageSize: 10,
      },
      pagesTotal: 0,
      isShowPage: false,
      rules: {},
    };
  },
  mounted() {
    this.getList();
  },
  computed: {},
  methods: {
    getList() {
      this.loading.list = true;
      fetchEMUPage(this.pages)
        .then((res) => {
          console.log(res);
          let data = res.result;
          this.list = data.records;
          this.pagesTotal = data.total;
          this.isShowPage = true;
          this.loading.list = false;
        })
        .catch((e) => {
          this.$message.error(e);
          this.loading.list = false;
        });
    },
    add() {
      this.$router.push({
        path: '/click/emu/add/v3',
      });
    },
    edit(id) {
      this.$router.push({
        path: '/click/emu/edit/v3',
        query: {
          id: id,
        },
      });
    },
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          console.log(id);
          fetchEMUDel({ id: id }).then((res) => {
            console.log(res);
            this.getList();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
